import { createActions } from 'redux-actions';

const {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
}: {
    getPost?: any;
    getPostSuccess?: any;
    getPostFailure?: any;
    getPostIdle?: any;
} = createActions(
    'GET_POST',
    'GET_POST_SUCCESS',
    'GET_POST_FAILURE',
    'GET_POST_IDLE'
);

export { getPost, getPostSuccess, getPostFailure, getPostIdle };
