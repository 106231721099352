import { createActions } from 'redux-actions';

const {
    getHomepage,
    getHomepageSuccess,
    getHomepageFailure,
    getHomepageIdle,
    getPosts,
    getPostsSuccess,
    getPostsFailure,
    getPostsIdle,
    getPostsClear,
}: {
    getHomepage?: any;
    getHomepageSuccess?: any;
    getHomepageFailure?: any;
    getHomepageIdle?: any;
    getPosts?: any;
    getPostsSuccess?: any;
    getPostsFailure?: any;
    getPostsIdle?: any;
    getPostsClear?: any;
} = createActions(
    'GET_HOMEPAGE',
    'GET_HOMEPAGE_SUCCESS',
    'GET_HOMEPAGE_FAILURE',
    'GET_HOMEPAGE_IDLE',
    'GET_POSTS',
    'GET_POSTS_SUCCESS',
    'GET_POSTS_FAILURE',
    'GET_POSTS_IDLE',
    'GET_POSTS_CLEAR'
);

export {
    getHomepage,
    getHomepageSuccess,
    getHomepageFailure,
    getHomepageIdle,
    getPosts,
    getPostsSuccess,
    getPostsFailure,
    getPostsIdle,
    getPostsClear,
};
