import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getEventsFuture,
    getEventsFutureSuccess,
    getEventsFutureFailure,
    getEventsFutureIdle,
    getEventsFutureClear,
    getEventsPast,
    getEventsPastSuccess,
    getEventsPastFailure,
    getEventsPastIdle,
    getEventsPastClear,
    getEventMonth,
    getEventMonthSuccess,
    getEventMonthFailure,
    getEventMonthIdle,
    getEventMonthClear,
} from './actions';

import { Post } from 'types/models';
import { getReguest } from '../app/api';

function* getEventMonthSaga(action: any): any {
    try {
        const [eventMonth] = yield all([
            call(getReguest, {
                route:
                    '/api/events?' +
                    'fields[0]=Title' +
                    '&fields[1]=Slug' +
                    '&fields[2]=Short_info' +
                    '&populate[0]=Card_background' +
                    '&filters[Is_event_month][$eq]=1',
            }),
        ]);

        yield put(
            getEventMonthSuccess({
                eventMonth,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getEventMonthFailure(error));
    }
}

function* getEventsFutureSaga(action: any): any {
    try {
        const { payload } = action;

        const { filter } = payload;

        const [futureEvents] = yield all([
            call(getReguest, {
                route:
                    '/api/events?' +
                    'fields[0]=Title' +
                    '&fields[1]=Slug' +
                    '&fields[2]=Short_info' +
                    '&fields[3]=Title_button' +
                    '&fields[4]=Start' +
                    '&sort=Start:asc' +
                    '&populate[0]=Card_background' +
                    filter,
                // '&sort=Sort',
                // '&sort[0]=Sort:desc'+ +
            }),
        ]);
        yield put(
            getEventsFutureSuccess({
                futureEvents,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getEventsFutureFailure(error));
    }
}

function* getEventsPastSaga(action: any): any {
    try {
        const { payload } = action;

        const { filter } = payload;

        const [pastEvents] = yield all([
            call(getReguest, {
                route:
                    '/api/events?' +
                    'fields[0]=Title' +
                    '&fields[1]=Slug' +
                    '&fields[2]=Short_info' +
                    '&fields[3]=Title_button' +
                    '&fields[4]=Start' +
                    '&sort=Start:asc' +
                    '&populate[0]=Card_background' +
                    filter,
                // '&sort=Sort',
                // '&sort[0]=Sort:desc'+ +
            }),
        ]);

        yield put(
            getEventsPastSuccess({
                pastEvents,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getEventsPastFailure(error));
    }
}

export function* eventsWatcher() {
    yield takeEvery(getEventsFuture.toString(), getEventsFutureSaga);
    yield takeEvery(getEventsPast.toString(), getEventsPastSaga);
    yield takeEvery(getEventMonth.toString(), getEventMonthSaga);
}
